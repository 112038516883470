import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import VueRouter from 'vue-router'
import routers from './router/index'
import axios from 'axios'
import VueAxios from 'vue-axios'
 axios.defaults.baseURL='/api'
//axios.defaults.baseURL=process.env.VUE_APP_URL
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
Vue.use(Avue);

axios.interceptors.request.use(
    config => {
      const token = sessionStorage.getItem("token");
      if(token !== undefined && token != null){
          token && (config.headers.token = token);
      }
      return config;
    },
    error => {
      return Promise.error(error);
    })

axios.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.data.code === 203) {
            sessionStorage.removeItem("userName")
            router.push({
                path: '/',
            })
            return Promise.reject(response);
        } else {
            return Promise.resolve(response);
        }
    }
)


Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(VueAxios,axios);
const router = new VueRouter({
  mode: 'history',
  routes: routers
})
new Vue({
  render: h => h(App),
  router
}).$mount('#app')


